import { BackgroundImage, BackgroundImageRow, BackgroundImageWrapper, BackgroundWrapper } from "./styles";
import cloud from '../../assets/images/cloud/cloud.png';

export const Background = () => {

    const backgroundImagesAcross = 10;
    const backgroundImagesDown = 10;

    var backgroundImages: JSX.Element[] = [];

    for(var i = 0; i < backgroundImagesAcross; i++) {
        var backgroundImageRow: JSX.Element[] = [];
        for (var j = 0; j < backgroundImagesDown; j++) {
            backgroundImageRow.push(
                <BackgroundImageWrapper height={100 / backgroundImagesDown + "vh"} width={100 / backgroundImagesAcross + "vw"} key={`background-${i}-${j}`}>
                    <BackgroundImage src={cloud}/>
                </BackgroundImageWrapper>

            )
        }
        backgroundImages.push(
            <BackgroundImageRow key={`background-row-${i}`}>
                {backgroundImageRow}
            </BackgroundImageRow>
            );
    }

    return (
        <BackgroundWrapper>
            {backgroundImages}
        </BackgroundWrapper>
    )
};