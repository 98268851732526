import styled from 'styled-components';
import { Colours } from '../../styles/colours';

export const MainContainer = styled.div`
    display: flex;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    
    justify-content: center;
    align-items: center;

    background-color: #F8F0E3;

    overflow: hidden;
`;

export const ContentsWrapper = styled.div`
    width: 20rem;
    height: 20rem;

    z-index: 10;
`;

export const Circle = styled.div`
    display: flex;
    height: 100%;
    width: 100%;

    justify-content: center;
    align-items: center;

    border: 12px double black;
    border-radius: 50%;

    background-color: ${Colours.OffWhite};
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;

    height: 80%;
    width: 80%;

    justify-content: center;
    align-items: center;

    font-family: Thirteen;
    font-size: 5rem;

    -webkit-text-fill-color: black;
`;

export const BackgroundWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
`;

export const BackgroundImageRow = styled.div`
    display: flex;
    flex-direction: row;
    
    justify-content: space-between;
    align-items: space-between;
`;

export interface BackgroundImageWrapperProps {
    width: string;
    height: string;
}

export const BackgroundImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props: BackgroundImageWrapperProps) => props.width};
    height: ${(props: BackgroundImageWrapperProps) => props.height};
`;

export const BackgroundImage = styled.img`
    display: block;
    height: 80%;
    width: 80%;

    z-index: 5;

    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
`;

