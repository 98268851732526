import styled, { keyframes } from 'styled-components';

export interface WigglyLetterProps {
    delay: number;
}

export const WiggleAnimation = keyframes`
    0% { top: 0px }
    50% { top: -20px }
    100% { top: 0px }
`;

export const WigglyLetter = styled.div`
    animation-name: ${WiggleAnimation};
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-delay: ${(props: WigglyLetterProps)  => props.delay * 100}ms;

    position: relative;
`;