import { MainContainer, ContentsWrapper, Circle, TextWrapper } from './styles';
import { Background } from './background';
import { useEffect, useState } from 'react';
import { WigglyLine } from '../molecules/wigglyLine/WigglyLine';
import { MusicState, DefaultMusicState } from '../musicController/MusicState';
import { MusicController } from '../musicController/MusicController';
import { Scene1 } from '../scenes/scene1/scene1';

export const Main = () => {

    const [musicState, updateMusicState] = useState<MusicState>(DefaultMusicState);

    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const onHillClick = (hatsPattern: number[]) => {
        updateMusicState({...musicState, hatsPattern: hatsPattern})
    }

    const onDogeClick = (dogePattern: number[]) => {
        updateMusicState({...musicState, dogePattern: dogePattern})
    }

    return (
        <MainContainer>
            <ContentsWrapper>
                <MusicController isPlaying={isPlaying} state={musicState} />
                <Scene1 onHillClick={onHillClick} onDogeClick={onDogeClick} />
                <Circle onClick={() => setIsPlaying(!isPlaying)}>
                    <TextWrapper>
                        <WigglyLine>
                            GROOVY
                        </WigglyLine>
                        <WigglyLine delayOffset={5}>
                            WAVY
                        </WigglyLine>
                        <WigglyLine delayOffset={10}>
                            GRAVY
                        </WigglyLine>
                    </TextWrapper>
                </Circle>
            </ContentsWrapper>
            <Background />
            
        </MainContainer>
    )
};