import { useState } from "react";
import { HatBeat1, HatBeat2, HatBeat3 } from "../../beats/hats/hatBeats";
import { DogeBeat1 } from '../../beats/doge/dogeBeats';
import { Doge, DogeWrapper, Hill, SceneWrapper } from "./styles";
import DogeSrc from '../../../assets/images/doge/doge1.png';

export interface Scene1Props {
    onHillClick: (hatsPattern: number[]) => void;
    onDogeClick: (dogePattern: number[]) => void;
}

export const Scene1 = ({onHillClick, onDogeClick}: Scene1Props) => {

    const [hillSelected, setHillSelected] = useState<number>(-1);
    const [dogeState, setDogeState] = useState<number>(-1);

    const handleHill1Click = () => {
        setHillSelected(1);
        onHillClick(HatBeat1);
    }

    const handleHill2Click = () => {
        setHillSelected(2);
        onHillClick(HatBeat2);
    }

    const handleHill3Click = () => {
        setHillSelected(3);
        onHillClick(HatBeat3);
    }

    const handleDogeClick = () => {
        var nextDogeState = dogeState + 1;
        var dogeBeat: number[] = [];
        
        switch(nextDogeState) {
            case 0:
                dogeBeat = DogeBeat1;
                break;
            case 1:
            case 2:
                break;
        }

        onDogeClick(dogeBeat);

        if (dogeState >= 2) nextDogeState = -1;
        setDogeState(nextDogeState);
    }

    return (
        <SceneWrapper>
            <Hill 
                position={{top: "80vh", left: "3vw"}} 
                height={'50vh'} 
                width={'15vw'} 
                z={29} 
                onClick={handleHill1Click} 
                isSelected={hillSelected == 1}
                animationDelay={1}
            />
            <Hill
                position={{top: "60vh", left: "15vw"}} 
                height={'70vh'} 
                width={'20vw'} 
                z={26} 
                onClick={handleHill2Click} 
                isSelected={hillSelected == 2}
                animationDelay={2}
            />
            <Hill
                position={{top: "50vh", left: "8vw"}} 
                height={'100vh'} 
                width={'17vw'} 
                z={24} 
                onClick={handleHill3Click} 
                isSelected={hillSelected == 3}
                animationDelay={2}
            />
            <DogeWrapper 
                position={{top: '75vh', left: '45vw'}} 
                width={'10vw'}
                height={'10vw'} 
                z={29}
                onClick={handleDogeClick}
            >
                <Doge src={DogeSrc} />
            </DogeWrapper>
        </SceneWrapper>
    )
}
