import { WigglyLetter as StyledWigglyLetter } from './styles';

export interface WigglyLetterProps {
    children: string,
    delay: number
}

export const WigglyLetter = ({children, delay}: WigglyLetterProps) => {
    return (
        <StyledWigglyLetter delay={delay}>
            {children}
        </StyledWigglyLetter>
    )
};