export interface MusicState {
    tempo: number;
    kickPattern?: number[];
    snarePattern?: number[];
    hatsPattern?: number[];
    dogePattern?: number[];
}

export const DefaultMusicState: MusicState = {
    tempo: 90,
    kickPattern: [0, 8, 10],
    snarePattern: [4, 12]
}