import { WigglyLetter } from "../../atoms/wigglyLetter/wigglyLetter";
import { WigglyLineWrapper } from "./styles";

export interface WigglyLineProps {
    children: string,
    delayOffset?: number
}

export const WigglyLine = ({children, delayOffset = 0}: WigglyLineProps) => {
    
    var wigglyLetters: JSX.Element[] = children
        .split("")
        .map((letter, index) =>{ 
            return (<WigglyLetter delay={index + delayOffset} key={index}>{letter}</WigglyLetter>)
        }); 

    return (
        <WigglyLineWrapper>
            {wigglyLetters}
        </WigglyLineWrapper>
    )
} 