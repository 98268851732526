import { MusicState } from "./MusicState";
import {
    Analyser,
    Song,
    Sequencer,
    Sampler,
    Synth,
  } from 'react-music';

export interface MusicControllerProps {
    isPlaying: boolean;
    state: MusicState;
}

export const MusicController = ({isPlaying, state}: MusicControllerProps) => {

    const kickSampler = state.kickPattern &&
    <Sampler
        sample="samples/kick.wav"
        steps={state.kickPattern}
    />

    const snareSampler = state.snarePattern &&
    <Sampler
        sample="samples/snare.wav"
        steps={state.snarePattern}
    />

    const hatsSampler = state.hatsPattern &&
    <Sampler
        sample="samples/hihat.wav"
        steps={state.hatsPattern}
    />

    const dogeSampler = state.dogePattern &&
    <>
        <Sampler
            sample="samples/doge.wav"
            steps={state.dogePattern}
        />
    </>

    return (
        <>
        {isPlaying &&
            <Song
                playing={true}
                tempo={state.tempo}
            >
                <Sequencer
                    resolution={16}
                    bars={1}
                >
                    {kickSampler}
                    {snareSampler}
                    {hatsSampler}
                    {dogeSampler}
                </Sequencer>
            </Song>}
        </>
    )
} ;