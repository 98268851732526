import styled from 'styled-components';

export const WigglyLineWrapper = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    justify-content: center;
    align-items: center;
`;