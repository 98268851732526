import styled, { keyframes } from 'styled-components';
import { NumberLiteralType } from 'typescript';
import { Colours } from '../../../styles/colours';

export interface PositionProps {
    top?: string;
    left?: string;
}

export const SceneWrapper = styled.div`
    position: absolute;

    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    overflow: hidden;

    z-index: -1;
`;

export const HillWiggleAnimation = keyframes`
    25% { transform: translateY(-5%) }
    75% { transform: translateY(5%) }
`;

export interface HillProps {
    width: string;
    height: string;
    z: number;
    position: PositionProps;
    isSelected: boolean;
    animationDelay: number;
}

export const Hill = styled.div`
    ${(props: HillProps) => 
        `
        position: absolute;

        width: ${props.width};
        height: ${props.height};

        top: ${props.position.top ?? '0'};
        left: ${props.position.left ?? '0'};

        transition: top ease 1s;

        z-index: ${props.z};

        background-color: ${Colours.OffWhite};

        border: ${props.isSelected ? '16px solid black' : '8px solid black'};

        border-radius: 150px;

        animation-duration: 8s;
        animation-iteration-count: infinite;
        animation-delay: ${props.animationDelay * 100}ms;
        `
    }

    animation-name: ${(props: HillProps) => props.isSelected ? HillWiggleAnimation : 'none'};
`;

export interface DogeProps {
    width: string;
    height: string;
    position: PositionProps;
    z: number;
}

export const DogeWiggleAnimation = keyframes`
    25% { transform: rotate(-15deg) }
    75% { transform: rotate(15deg) }
`;

export const DogeWrapper = styled.div`
    ${(props: DogeProps) => `
        position: absolute;

        width: ${props.width};
        height: ${props.height};

        top: ${props.position.top ?? '0'};
        left: ${props.position.left ?? '0'};

        z-index: ${props.z};

        animation-duration: 4s;
        animation-iteration-count: infinite;
        `
    }   

    animation-name: ${DogeWiggleAnimation};
`;

export const Doge = styled.img`
    height: 100%;
    width: 100%;
`;